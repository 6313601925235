import SvgColor from "../common/svgColor";
import Iconify from "../common/iconify";

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const navConfig = [
  {
    title: "dashboard",
    path: "/",
    icon: icon("ic_analytics")
  },
  {
    title: "KYC",
    path: "/kyc",
    icon: icon("ic_lock")
  },
  {
    title: "Schedules",
    path: "/schedules",
    icon: icon("ic_schedules")
  },
  {
    title: "Billing",
    icon: icon("ic_billing"),
    children: [
      {
        title: "Invoices",
        path: "/invoices",
        icon: icon("ic_invoice")
      },
      {
        title: "Subscription",
        path: "/subscription",
        icon: icon("ic_subscription")
      },
      {
        title: "Credit Notes",
        path: "/creditNotes",
        icon: <Iconify icon="majesticons:creditcard-hand-line" />
      },
      {
        title: "Transactions",
        path: "/transactions",
        icon: <Iconify icon="grommet-icons:transaction" />
      }
    ]
  },
  {
    title: "Delivery",
    icon: icon("ic_delivery_group"),
    children: [
      // {
      //   title: "Deliver",
      //   path: "/addDeliveryDetails",
      //   icon: icon("ic_delivery")
      // },
      {
        title: "Exchange",
        path: "/exchange",
        icon: icon("ic_exchange")
      },
      {
        title: "View Del & Exch",
        path: "/showDeliveryExchangeList",
        icon: icon("ic_status_view")
      }
    ]
  },
  {
    title: "Pickup",
    icon: icon("ic_return_group"),
    path: "/viewPickUpDetails"
    // children: [
    //   {
    //     title: "Pickup",
    //     path: "/pickUp",
    //     icon: icon("ic_pickup")
    //   },
    //   {
    //     title: "View Pickups",
    //     path: "/viewPickUpDetails",
    //     icon: icon("ic_status_view")
    //   }
    // ]
  },
  {
    title: "Products/Services",
    icon: icon("ic_product_class"),
    children: [
      {
        title: "Products",
        path: "/products",
        icon: icon("ic_product")
      },
      {
        title: "Model",
        path: "/productModel",
        icon: icon("ic_model_icon")
      },
      {
        title: "Services",
        path: "/services",
        icon: icon("ic_service")
      },
      {
        title: "Variant",
        path: "/variant",
        icon: icon("ic_variant_icon")
      },
      {
        title: "Price Tags",
        path: "/priceTags",
        icon: icon("ic_priceTag")
      }
    ]
  },
  {
    title: "Customers",
    path: "/customers",
    icon: icon("ic_customers")
  },
  {
    title: "User",
    path: "/users",
    icon: icon("ic_user")
  },
  {
    title: "Old D & P",
    path: "/old-deliveryPickup",
    icon: icon("ic_shipping")
  }
];

export default navConfig;
